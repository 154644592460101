<template>
    <div class="template-page people people-page">
        <basic-page :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <h3>Filter by</h3>
            <div class="filtri">

                <div class="tags-filter">
                    <div>
                        <div class="accordion" v-show="this.isOpen">
                            <button type="checkbox" :class="{'image-tag': true, 'active': checkActive(t)}"
                                v-for="(t, index) in this.tagsArray" :key="'tag'+index" :value="t" name="filtertag"
                                @click="addFilter">{{ t }}</button>
                        </div>

                        <div class="ricerca-testo" v-show="this.isOpen">
                            <input v-model="research" placeholder="Search the content...">
                        </div>
                    </div>

                    <p class="label-accordion" @click="openAccordion()">
                        <span v-if="this.isOpen">- Nascondi filtri</span>
                        <span v-if="!this.isOpen">+ Mostra filtri</span>
                    </p>
                </div>

            </div>



            <div class="people-wrapper" v-if="this.filteredPeople.length>0" >
                <div class="container title">
                    <h4>All people</h4>
                </div>
                <transition-group name="list" tag="div">
                    <div v-for="(pers, index) in this.filteredPeople" class="item-person" :key="'person'+index">
                        <router-link :to="personLink(pers)">
                            <person-tile :personData="pers"></person-tile>
                        </router-link>
                    </div>
                </transition-group>
            </div>

            <div class="people-wrapper" v-if="this.filteredStudents.length>0">
                <div class="container title">
                    <h4>Students</h4>
                </div>
                <transition-group name="list" tag="div">
                    <div v-for="(pers, index) in this.filteredStudents" class="item-person" :key="'person'+index">
                        <router-link :to="personLink(pers)">
                            <person-tile :personData="pers"></person-tile>
                        </router-link>
                    </div>
                </transition-group>
            </div>


            
            <div class="people-wrapper" v-if="this.filteredFormer.length>0" >
                <div class="container title">
                    <h4>Former Members</h4>
                </div>
                <transition-group name="list" tag="div">
                    <div v-for="(pers, index) in this.filteredFormer" class="item-person" :key="'person'+index">
                        <router-link :to="personLink(pers)">
                            <person-tile :personData="pers"></person-tile>
                        </router-link>
                    </div>
                </transition-group>
            </div>


        </basic-page>
    </div>
</template>
<script>
    import BasicPage from '../views/BasicPage'
    import {
        fetchNodes,
        fetchSingleNode
    } from '../libs/drupalClient'
    import {
        detailsLink
    } from '../libs/utils'
    import PersonTile from '../components/PersonTile.vue'
    export default {
        name: 'people',
        components: {
            BasicPage,
            PersonTile
        },
        data: () => {
            return {
                peopleData: [],
                studentsData: [],
                otherPeople: [],
                formerPeople: [],
                pageData: {},
                currentPageId: "378ee55b-97c5-47d4-9fcb-ec5bdaee512a",
                bannerimg: null,
                tagsArray: [
                    'Academic Board Member',
                    'Academic Staff',
                    'Administrative Staff',
                    'Research Group Leader',
                    'Student',
                    'Researcher',
                    'Former Member',
                    'Visiting Scientist'
                ],
                filteringTags: [],
                research: '',
                isOpen: null,
            }
        },
        methods: {
            openAccordion() {
                this.isOpen = !this.isOpen
            },
            personLink(p) {
                return detailsLink('person', p);
            },
            addFilter(event) {
                if (this.filteringTags.includes(event.target.value))
                    this.filteringTags.splice(this.filteringTags.indexOf(event.target.value), 1)
                else
                    this.filteringTags.push(event.target.value)
                    //console.log(this.filteringTags)
            },
            checkActive(t) {
                return this.filteringTags.includes(t)
            }
        },
        computed: {
            filteredFormer() {

                var pp = []
                if (this.filteringTags.length == 0)
                    pp = this.formerPeople
                else {
                    pp = this.formerPeople.filter(n => {
                        var r = false;
                        n.field_person_position.forEach(s => {
                            this.filteringTags.forEach(f => {
                                if(s.attributes.name.toLowerCase().includes(f.toLowerCase()))
                                    r = true
                            })
                        }) 
                        return r
                    })
                }
                return pp.filter(n => {
                    return (
                        n.attributes.field_first_name.toLowerCase().includes(this.research.toLowerCase()) ||
                        n.attributes.field_last_name.toLowerCase().includes(this.research.toLowerCase())
                    )
                })
            },
            filteredPeople() {

                var pp = []
                if (this.filteringTags.length == 0)
                    pp = this.otherPeople
                else {
                    pp = this.otherPeople.filter(n => {
                        var r = false;
                        n.field_person_position.forEach(s => {
                            this.filteringTags.forEach(f => {
                                if(s.attributes.name.toLowerCase().includes(f.toLowerCase()))
                                    r = true
                            })
                        }) 
                        return r
                    })
                }
                return pp.filter(n => {
                    return (
                        n.attributes.field_first_name.toLowerCase().includes(this.research.toLowerCase()) ||
                        n.attributes.field_last_name.toLowerCase().includes(this.research.toLowerCase())
                    )
                })
            },
            filteredStudents() {
                var pp = []
                if (this.filteringTags.length == 0)
                    pp = this.studentsData
                else {
                    pp = this.studentsData.filter(n => {
                        var r = false;
                        n.field_person_position.forEach(s => {
                            this.filteringTags.forEach(f => {
                                if(s.attributes.name.toLowerCase().includes(f.toLowerCase()))
                                    r = true
                            })
                        }) 
                        return r
                    })
                }

                return pp.filter(n => {
                    return (
                        n.attributes.field_first_name.toLowerCase().includes(this.research.toLowerCase()) ||
                        n.attributes.field_last_name.toLowerCase().includes(this.research.toLowerCase())
                    )
                })
            }
        },
        mounted() {
            if (window.innerWidth < 1024) {
                this.isOpen = false
            } else {
                this.isOpen = true
            }

            fetchSingleNode('page', this.currentPageId, {
                    include: ['field_banner_top']
                })
                .then(res => {
                    this.pageData = res[0].attributes
                    if (res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
                })
            fetchNodes('person', {
                    include: ['field_person_photo', 'field_person_position']
                })
                .then(res => {

                    this.peopleData = res.sort((a, b) => {
                        return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1
                    })


                    this.formerPeople = this.peopleData.filter((person) =>{
                        let x= false
                            person.field_person_position.some((role)=>{
                                if(role.attributes.name === 'Former Member'){
                                    x= true
                                }
                            })
                            return x
                        }).map((person) =>{
                            return person
                        }
                    )

                    this.otherPeople = this.peopleData.filter((person) =>{
                        let x= false
                            person.field_person_position.every((role)=>{
                               
                               if(
                                    role.attributes.name !== 'Former Member' &&
                                    role.attributes.name !== 'MSc Student' &&
                                    role.attributes.name !== 'PhD Student' &&
                                    role.attributes.name !== 'Student' 
                                ){
                                    x= true
                                }
                            })
                            return x
                        }).map((person) =>{
                            return person
                        }
                    )


                    this.studentsData = this.peopleData.filter((person) =>{
                        let x= false
                            person.field_person_position.every((role)=>{
                                if(
                                    role.attributes.name !== 'Former Member' &&
                                    role.attributes.name === 'MSc Student' ||
                                    role.attributes.name === 'PhD Student' ||
                                    role.attributes.name === 'Student' 
                                ){
                                    x= true
                                }
                            })
                            return x
                        }).map((person) =>{
                            return person
                        }
                    )


                })
        }
    }
</script>

<style lang="scss">
@import "../styles/colors.scss";
.people-page .contact-info {
    display: none;
}

.tags-filter {
    > div {
        display: flex;
    }
}

.label-accordion {
    display: none;
}

.label-accordion {
    position: relative;
    margin-bottom: 20px;
    margin-top:1rem;
    width: 100%;

    span {
        position: relative;
        border-radius: 30px;
        display: inline-block;
        font-weight: 500;
        padding: 5px 20px;
        cursor: pointer;
        border: 1px solid #acacac;
    }

    &:before {
        content: "";
        width: 100%;
        height: 2px;
        display: block;
        left: 0;
        position: absolute;
    }
}

@media (max-width:1024px) {
    .tags-filter {
        display: block;
    }

    .label-accordion {
        display: block;
    }
}

.accordion.hide {
    display: none;
}

.person-wrapper {
    //margin: 20px auto;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.people-wrapper {
    width: 100%;
    margin-top: 20px;

    .title {
        padding-left: 0;

        h4 {
            color: $greyColor;
            font-weight: 300;
            font-size: 1.8em;
        }
    }

    > div {
        /* display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        justify-content: left;
        flex-direction: row; */
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 40px 0;
        max-width: 1200px;
        margin: 40px auto;

        .item-person {
            a { height: 100%; display: block; }

            //width: 25%;
            width: 100%;
            height: 100%;
            min-width: 265px;
            //max-width: 265px;
            
            /* @media (min-width: 1201px) {
                max-width: 300px;
            }

            @media (max-width: 768px) {
                max-width: unset;
                width: 45%;
            }

            @media (max-width: 576px) {
                //width: 90%;
                width: 100%;
                max-width: unset;
            } */
        }
    }
}

@media(max-width:1024px) {
    .tags-filter {
        >div {
            display: flex;
            flex-direction: column;
        }

        .ricerca-testo {
            margin-top: 1rem;
        }
    }
}

/* @media (max-width:768px) {
    .people-wrapper>div {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
    }
} */

@media(max-width: 1200px) {
    .people-wrapper > div { grid-template-columns: repeat(3, 1fr); max-width: unset; }
}

@media(max-width: 992px) {
    .people-wrapper > div { grid-template-columns: repeat(2, 1fr); max-width: unset; }
}

@media(max-width: 576px) {
    .people-wrapper > div { grid-template-columns: 1fr; max-width: unset; }
}

</style>