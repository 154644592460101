<template>
  <div class="person-wrapper">
    <div class="data">
      
      <div class="profile-picture">
        <img v-if="personData" :src="profilePhoto">
      </div>

      <h3 class="person-name">{{ personData.attributes.field_first_name + ' ' + personData.attributes.field_last_name}}</h3>
       
      <div class="role-wrapper">
        <p v-for="(role, index) in personData.field_person_position" :key="personData.attributes.field_first_name+'role'+index"
          class="person-role">{{ role.attributes.name }}
        </p>
      </div>

      <div v-if="personData.attributes.field_person_contact_info">
        <div class="contact-info" v-html="personData.attributes.field_person_contact_info.value"></div>
      </div>

    </div>
    <div :class="{
        'contacts': true,
        //'gray-bg': !(personData.attributes.field_email[0] || personData.attributes.field_phone || personData.attributes.field_person_office)
      }">
      <p class="office" v-if="personData.attributes.field_person_office">
        {{ personData.attributes.field_person_office }}</p>

      <div v-if="personData.attributes.field_email[0]">
        <p class="email" v-for="(m, index) in personData.attributes.field_email" :key="index">
          {{m}}
        </p>
      </div>


      <p class="number" v-if="personData.attributes.field_phone">
        {{ personData.attributes.field_phone }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'person-tile', 
  data: () => {
    return {
      longDescription: false,
    }
  },
  props: {
    personData: Object
  },
  methods: {
    readMore() {
      this.longDescription = !this.longDescription;
    }
  },
  computed: {
    profilePhoto() {
      if(this.personData.field_person_photo) 
        return (process.env.VUE_APP_ENDPOINT + this.personData.field_person_photo.attributes.uri.url)
      else 
        return '/placeholder-people.svg';
    }
  }, 
  mounted() {
    //console.log('p',this.personData.attributes.field_email);
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
 
.gray-bg {
  background: rgb(245, 245, 245);
}

.contact-info ::v-deep p{
  font-size: 0.875rem;
  line-height: 1.4rem;
  color: #333333;
  margin-top: 10px;
}

h3 {
  font-size: 1.4em;
}

.data {
  padding: 1.5em;
  padding-top: 0;
  border: 1px #e9e9e9 solid;
  min-height: 400px;
  //min-height: 250px;
  height: 100%;
}

.contacts {
  padding: 1em 2rem;
  border: 1px #e9e9e9 solid;
  min-height: 170px;
  //min-height: 100px;
  height: fit-content;
  .number {
    margin-top: 0px;
  }
}

.profile-picture{
  width: 190px;
  overflow: hidden;
  position: relative;
  height: 190px;
  &::before{
      content: "";
      opacity: 0.5;
      background-image: url("../assets/pattern-overlay-person.png");
      width: 100%;
      height: 100%;
      z-index: 0;
      top: 0;
      right: 0px;
      display: block;
      position: absolute;
  }
  img{
    width: 190px;
    object-fit: cover;
    height: 100%;
  } 
}

.person-name {
  color: $secondaryColor;
  text-transform: none;
  padding: 0.9rem 0rem 0.5rem 0rem;

}

.role-wrapper {
  display: flex; display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: 46px;
}

.person-role {
  line-height: 1.5em;
  height: fit-content;
  padding: .3em .7em;
  margin: 0.5em 0.5em 0em 0;
  background: $mainColor;
  color: $secondaryColor;
  font-weight: 700;
  font-size: 0.8125rem;
  text-transform: uppercase;
  width: fit-content;
}

.email {
  color: darken($mainColor,10%);
  font-weight: 500;
  margin-bottom: 7px;
}

.accordion-readmore {
  position: relative;
  color: $secondaryColor;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  margin-top: 2em;
  margin-bottom: 1em;
  display: block;  
  width: fit-content;

  &:hover {
    color: lighten($secondaryColor, 20%);
  }

  &:before {
    content: '';
    background: $mainColor;
    width: 8px;
    height: 2em;
    top: -.25em;
    left: 0;
    position: absolute;
    display: block;
    z-index: -1;
  }

  &:after {
    content: '';
    top: .25em;
    right: -1.5em;
    background-image: url('../assets/dropdown-icon.svg');
    width: 1em;
    height: 1em;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    display: inline-block;
  }
}

.person-desc-long > .person-desc:not(.show-long-description) {
  display: none;
}

@media (max-width:768px){
  .person-wrapper {
    min-width:auto;
    width: 100%;
  }

}

@media (max-width:576px){
  .person-role,
  .role-wrapper {
    min-height: auto;
  }
  .data {
    min-height: unset;
  }
  .contacts {
    min-height: auto;
    height: auto;
}

}

</style>
